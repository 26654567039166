<template>
  <b-card
    :class="{ 'bg-light-yellow': isEditing, 'bg-light-green': !isEditing }"
    header-tag="header"
  >
    <div slot="header">
      <span v-if="isEditing"><i class="fa icon-pencil" /> {{ $t('global.edit') }} </span>
      <span v-else><i class="fa icon-plus" /> {{ $t('global.add_new') }} </span>
      <div class="card-actions">
        <a
          href="#"
          class="btn btn-setting"
          @click="closeClick"
        >
          <i class="fa icon-close" />
        </a>
      </div>
    </div>
    <form @submit.prevent="save">
      <b-form-group
        :label="$t('global.name')"
        :state="!errors.name"
        :invalid-feedback="errors.name"
      >
        <b-form-input
          v-model="product.name"
          type="text"
        />
      </b-form-group>
      <b-form-group
        :label="$t('global.description')"
        :state="!errors.description"
        :invalid-feedback="errors.description"
      >
        <b-form-input
          v-model="product.description"
          type="text"
        />
      </b-form-group>
      <b-form-group
        :label="$t('product_types.human_product')"
        :state="!errors.human_product_id"
        :invalid-feedback="errors.human_product_id"
      >
        <b-select-2
          v-model="product.human_product_id"
          :options="options.humanProducts"
        />
      </b-form-group>
      <b-form-group
        :label="$t('product_types.one_per_plate')"
        :state="!errors.one_per_plate"
        :invalid-feedback="errors.one_per_plate"
      >
        <b-form-checkbox
          v-model="product.one_per_plate"
        />
      </b-form-group>
      <div>
        <b-btn
          v-if="showCancel || isEditing"
          variant="outline-danger"
          @click="closeClick"
        >
          {{ $t('global.cancel') }}
        </b-btn>
        <b-btn
          v-if="isEditing"
          class="pull-right"
          type="submit"
          variant="success"
        >
          {{ $t('global.save') }}
        </b-btn>
        <b-btn
          v-else
          class="pull-right"
          type="submit"
          variant="success"
        >
          {{ $t('global.save') }}
        </b-btn>
      </div>
    </form>
  </b-card>
</template>
<script>

import { VuexTypes } from '@/store/types';
import { mapActions, mapState } from 'vuex';

import HumanProductsService from '@/services/human-products-service';

export default {
  name: 'ProductTypesForm',

  props: {
    value: {
      type    : Object,
      required: true,
    },
    showCancel: {
      type    : Boolean,
      required: false,
      default () {
        return false;
      },
    },
  },
  data () {
    return {
      product: _.cloneDeep(this.value),
      errors: {},
      options: {
        humanProducts: [],
      },
    };
  },
  computed: {
    ...mapState(['productTypes']),
    isEditing () {
      return typeof this.product.id !== 'undefined' && this.product.id > 0;
    },
  },
  watch: {
    value: function (newVal) {
      this.product = newVal;
    },
  },
  mounted () {
    this.loadHumanProducts();
  },
  methods: {
    ...mapActions({
      add   : VuexTypes.PRODUCT_TYPES_ADD,
      update: VuexTypes.PRODUCT_TYPES_UPDATE,
    }),
    closeClick () {
      this.$emit('saved', false);
    },
    save () {
      const action = this.isEditing ? this.update(this.product) : this.add(this.product);

      action
        .then(() => {
          this.errors = {};
          this.$emit('saved', true);
        }).catch((error) => {
          this.errors = {};
          if (typeof error.data !== 'undefined') {
            this.errors = error.data;
          }
          for (const item in error.data) {
            this.errors[item] = this.errors[item].join(' ');
          }
        });
    },
    loadHumanProducts () {
      this.options.humanProducts = [];
      HumanProductsService.listAll()
        .then((response) => {
          let humanProducts = response.data.map((item) => {
            return {
              id: item.id,
              text: item.code ? `${item.code} - ${item.name}` : `${item.name}`,
            };
          });
          humanProducts.unshift({ id: '', text: '' });
          this.options.humanProducts = Object.assign([], humanProducts);
        })
        .catch(() => {
          this.$bvToast.toast(this.$t('global.connection_lost_service', { service: 'HumanProductsService.listAll' }), {
            title  : this.$t('global.error'),
            variant: 'danger',
            solid  : true,
          });
        });
    },
  },
};
</script>
<style>
</style>
