import Api from '@/services/api';

export default {
  list (currentPage) {
    let search = '';
    if (currentPage) {
      search = `?page=${currentPage}`;
    }
    return Api().get(`/cash_desk${search}`);
  },
  get (id) {
    return Api().get(`/cash_desk/${id}`);
  },
  store (data) {
    return Api().post('/cash_desk', data);
  },
  update (data) {
    return Api().put(`/cash_desk/${data.id}`, data);
  },
  delete (id) {
    return Api().delete(`/cash_desk/${id}`);
  },
  order (data) {
    return Api().post('/cash_desk/order', data);
  },
  check (data) {
    return Api().post('/cash_desk/check', data);
  },
  setTheme (data) {
    return Api().post('/cashier/cash_desk/set_theme', data);
  },
  cashierList () {
    return Api().get('/cashier/cash_desk');
  },
};
