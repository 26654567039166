<template>
  <div class="tray-header">
    <div class="tray-number">
      <span
        v-b-toggle="collapseId"
        class="toggler"
      >
        <img src="@/static/img/chevron-right.svg">
        {{ $t('global.tray') + ' ' + (trayIndex + 1) }}
      </span>
    </div>
    <div class="header-right">
      <div>
        {{ productCount(tray) + ' ' + $t('global.products') }}
      </div>
      <div>
        {{ getTrayTotal(tray) | toPrice }}
      </div>
      <b-dropdown 
        v-if="canEditOrderProducts"
        right
        variant="link"
        class="dot-dropdown"
        no-caret
      >
        <template #button-content>
          <img src="@/static/img/3-dots.svg">
        </template>
        <b-dropdown-item @click="confirmTrayDelete.show = true">
          {{ $t('cashier.cashDesk.orderTrays.tray.delete') }}
          <img
            id="trash_icon"
            src="@/static/img/trash.svg"
          >
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <modal-confirm-tray-delete
      v-model="confirmTrayDelete.show"
      :tray="tray"
    />
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import ModalConfirmTrayDelete from '@/views/Cashier/CashDesk/Modals/ModalConfirmTrayDelete.vue';
import { canEditOrderProducts } from '@/rules/order-rules';

export default {
  name      : 'TrayHeader',
  components: {
    ModalConfirmTrayDelete,
  },
  props: {
    tray: {
      type: Object,
      required: true,
    },
    trayIndex: {
      type: Number,
      required: true,
    },
    collapseId: {
      type: String,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      confirmTrayDelete: {
        show: false,
      },
    };
  },
  computed: {
    ...mapGetters('cashierTrays', {
      getTrayTotal: 'trayTotal',
    }),
    canEditOrderProducts() {
      return canEditOrderProducts(this.order);
    },
  },
  mounted() {
    
  },  
  methods: {
    productCount(tray) {
      if (!tray.tray_products) {
        return 0;
      }

      return tray.tray_products.length;

      // uncomment this, if you want to count unique products in tray
      // return tray.tray_products.map(item => item.human_product_id) 
      //   .filter((value, index, self) => self.indexOf(value) === index).length;
    },
  },
};
</script>

<style lang="scss" scoped>
.tray-header {
  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;

  .tray-number{
    font-weight: 800;
    font-size: 1.25rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    display: flex;
    align-self: center;
  }

  .header-right{
    display: flex;
    align-items: center;
    font-weight: 800;

    div{
      font-size: 1.125rem;
      line-height: 1.5rem;
      color: #777777;
      padding-left: 1.25rem;
    }
  }
  .toggler {
    &.not-collapsed {
      img {
        transform: rotate(90deg);
      }
    }

    img {
      -webkit-transition: 0.1s ease-in-out;
      -moz-transition: 0.1s ease-in-out;
      -o-transition: 0.1s ease-in-out;
      transition: 0.1s ease-in-out;
    }
  }
  #trash_icon{
    float: right;
  }
}
</style>
