<template>
  <b-card
    :class="{ 'bg-light-yellow': isEditing, 'bg-light-green': !isEditing }"
    header-tag="header"
  >
    <div slot="header">
      <span v-if="isEditing"><i class="fa icon-pencil" /> {{ $t('global.edit') }} </span>
      <span v-else><i class="fa icon-plus" /> {{ $t('global.add_new') }} </span>
      <div class="card-actions">
        <a
          href="#"
          class="btn btn-setting"
          @click="closeClick"
        >
          <i class="fa icon-close" />
        </a>
      </div>
    </div>
    <form @submit.prevent="save">
      <b-form-group
        :label="$t('global.name')"
        :state="!errors.name"
        :invalid-feedback="errors.name"
      >
        <b-form-input
          v-model="combo.name"
          type="text"
        />
      </b-form-group>
      <b-form-group
        v-if="activeLanguages.length > 0"
        :label="$t('global.name_translations')"
      >
        <div class="border px-3 pt-3">
          <b-form-group
            v-for="language in activeLanguages"
            :key="language.key"
            :label="language.key"
            label-cols="2"
          >
            <b-form-input
              v-model="combo.translations.name[language.key]"
              type="text"
            />
          </b-form-group>
        </div>
      </b-form-group>
      <b-form-group
        :label="$dt('combo.human_products', 'POS products')"
        :state="!errors.products"
        :invalid-feedback="errors.products"
      >
        <b-row
          v-for="(item, index) in selectedProducts"
          :key="index"
          class="form-product-row"
        >
          <b-col cols="11">
            <b-select-2
              v-model="selectedProducts[index]"
              :options="optionsHumanProducts"
            />
          </b-col>

          <b-col
            cols="1"
            class="remove-product-button-col"
          >
            <button
              v-if="index != 0"
              class="remove-product-button"
              @click.prevent="removeSelectField(index)"
            >
              <i class="fa icon-minus" />
            </button>
          </b-col>
        </b-row>
        <button
          class="add-product-button"
          @click.prevent="addSelectField"
        >
          <i class="fa icon-plus" />{{ $dt('combo.add_human_product', 'Add another POS product') }}
        </button>
      </b-form-group>
      <b-form-group
        :label="$dt('combo.product_set', 'POS combo')"
      >
        <b-select-2
          v-model="combo.human_product_id"
          :options="optionsHumanProducts"
        />
      </b-form-group>
      <div>
        <b-btn
          v-if="showCancel || isEditing"
          variant="outline-danger"
          @click="closeClick"
        >
          {{ $t('global.cancel') }}
        </b-btn>
        <b-btn
          v-if="isEditing"
          class="pull-right"
          type="submit"
          variant="success"
          :disabled="saving"
        >
          <b-spinner
            v-if="saving"
            label="Spinning"
            small
          />
          {{ $t('global.save') }}
        </b-btn>
        <b-btn
          v-else
          class="pull-right"
          type="submit"
          variant="success"
          :disabled="saving"
        >
          <b-spinner
            v-if="saving"
            label="Spinning"
            small
          />
          {{ $t('global.save') }}
        </b-btn>
      </div>
    </form>
  </b-card>
</template>

<script>
import { VuexTypes } from '@/store/types';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name      : 'ComboForm',
  props     : {
    value: {
      type    : Object,
      required: false,
    },
    showCancel: {
      type    : Boolean,
      required: false,
      default () {
        return false;
      },
    },
  },
  data () {
    return {
      combo: _.cloneDeep(this.value),
      errors: {},
      saving: false,
      selectedProducts: null,
    };
  },
  computed: {
    ...mapState(['combos']),
    ...mapGetters(['activeLanguages']),
    ...mapGetters(['humanProducts']),
    isEditing () {
      return this.combo?.id > 0;
    },
    optionsHumanProducts () {
      return this.humanProducts.map((item) => {
        return {
          id: item.id,
          text: item.code ? `${item.code} - ${item.name}` : `${item.name}`,
        };
      });
    },
  },
  watch: {
    value: function (newVal) {
      this.combo = newVal;
      this.selectedProducts = !this.isEditing ? [{id: null}] : this.value.human_products?.map((p) => p.id);
    },
  },
  mounted() {
    this.setSelectedProducts();
  },
  methods: {
    ...mapActions({
      add   : VuexTypes.COMBOS_ADD,
      update: VuexTypes.COMBOS_UPDATE,
    }),
    addSelectField() {
      this.selectedProducts.push({});
    },
    removeSelectField(index) {
      this.selectedProducts.splice(index, 1);
    },
    closeClick () {
      this.$emit('saved', false);
    },
    setSelectedProducts() {
      this.selectedProducts = !this.isEditing ? [{id: null}] : this.value.human_products.map((p) => p.id);
    },
    save () {
      this.saving = true;
      this.combo.products = this.selectedProducts;
      const action = this.isEditing ? this.update(this.combo) : this.add(this.combo);

      action
        .then((data) => {
          this.errors = {};

          this.$notify({
            type : 'success',
            title: this.$t('global.success'),
            text : this.$t('human_products.save_success'),
          });

          this.saving = false;

          this.$emit('saved', data.data.id);
        }).catch((error) => {
          this.errors = {};
          if (typeof error.data !== 'undefined') {
            this.errors = error.data;
          }
          for (const item in error.data) {
            this.errors[item] = this.errors[item].join(' ');
          }
          this.saving = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
  .add-product-button {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    margin-bottom: 1rem;
    
    i {
      margin-right: 0.5rem;
    }
  }
    .remove-product-button {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background-color: transparent;
      min-width: 10%;
    }

  .remove-product-button-col {
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
