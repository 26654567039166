import { VuexTypes } from '../../types';
import Vue from 'vue';
import traysService from '@/services/trays-service';
import { getHumanProduct } from '@/helpers/tray';

const state = {
  byOrder: {},
  loading: false,
  mapIds: {},
};

const getters = {
  findById: (state) => (id) => {
    for (let key in state.byOrder) {
      const tray = state.byOrder[key].find(el => el.id === id);

      if (tray) {
        return tray;
      }
    }
  },
  getTrayIndex: (state) => (trayId) => {
    const index = state.byOrder[state.mapIds[trayId]].findIndex(tray => tray.id === trayId);

    if (index === -1) {
      console.err('Vuex::cashier-trays::getTrayIndex Invalid tray argument', trayId);
    }

    return index;
  },
  trayTotal: (state, getters) => (tray) => {
    /* If tray is integer, find the responding object, else assign given */
    const trayObj = Number.isInteger(tray) ? getters.findById(tray) : tray;
    if (!trayObj) {
      console.err('Vuex::cashier-trays::trayTotal Invalid tray argument', tray);
    } else if (!trayObj.tray_products) {
      return 0;
    }

    let price = 0;
    trayObj.tray_products.forEach(product => {
      price += getHumanProduct(product).price;
    });

    return price;
  },
  orderTotal: (state, getters) => (orderId) => {
    if (!state.byOrder[orderId]) {
      return 0;
    }

    let total = 0;
    state.byOrder[orderId].forEach(tray => {
      total += getters.trayTotal(tray);
    });

    return total;
  },
};

const mutations = {
  [VuexTypes.CASHIER_SET_TRAY]: (state, tray) => {
    /* Check if Order key exists */
    if (!state.byOrder[tray.order_id]) {
      /* If does not, add key as array */
      Vue.set(state.byOrder, tray.order_id, []);
    } else {
      /* If does, check if tray already pushed and update it */
      const index = state.byOrder[tray.order_id].findIndex(existingTray => existingTray.id === tray.id);

      if (index !== -1) {
        Vue.set(state.byOrder[tray.order_id], index, tray);
        return;
      }
    }

    /* If try has not been inserted already - insert */
    tray._loading = false;
    state.byOrder[tray.order_id].push(tray);
    state.mapIds[tray.id] = tray.order_id;
  },
  [VuexTypes.CASHIER_TAYS_MUTATION_SET_IMAGE]: (state, { tray, imageData }) => {
    const item = state.byOrder[tray.order_id].find(e => e.id === tray.id);
    if (item) Vue.set(item, 'loadedImage', imageData);
  },
  [VuexTypes.CASHIER_TRAYS_MUTATION_SET_LOADING]: (state, value) => {
    state.loading = value ? true : false;
  },
  /* INSERT OR UPDATE TRAY */
  [VuexTypes.CASHIER_TRAYS_MUTATION_SET_TRAY_PRODUCT]: (state, { tray, trayProduct }) => {
    const indexOfTray = state.byOrder[tray.order_id]?.findIndex(existingTray => existingTray.id === tray.id);
    /* Cancel action if tray is not found */
    if (indexOfTray === -1) {
      console.error(`${VuexTypes.CASHIER_TRAYS_MUTATION_UPDATE_TRAY_PRODUCT} tray not found`, tray);
      return;
    }

    const trayProductIndex = state.byOrder[tray.order_id][indexOfTray]
      .tray_products.findIndex(existingTrayProduct => existingTrayProduct.id === trayProduct.id);

    if (trayProductIndex === -1) {
      /* Add tray product if not found */
      state.byOrder[tray.order_id][indexOfTray].tray_products.push(trayProduct);
    } else {
      /* Update existing tray product if found */
      Vue.set(state.byOrder[tray.order_id][indexOfTray].tray_products, trayProductIndex, trayProduct);
    }
  },
  [VuexTypes.CASHIER_TRAYS_MUTATION_DELETE_TRAY]: (state, trayId) => {
    const index = state.byOrder[state.mapIds[trayId]].findIndex(existingTray => existingTray.id === trayId);

    if (index === -1) {
      return console.log('CASHIER_TRAYS_MUTATION_DELETE_TRAY deleting nonexisting tray trayId:', trayId);
    }

    state.byOrder[state.mapIds[trayId]].splice(index, 1);
  },
  [VuexTypes.CASHIER_TRAYS_MUTATION_SET_TRAY_LOADING]: (state, { trayId, trayIndex, loading }) => {
    state.byOrder[state.mapIds[trayId]][trayIndex]._loading = loading;
  },
};

const actions = {
  [VuexTypes.CASHIER_ADD_TRAY]: ({ commit }, tray) => {
    commit(VuexTypes.CASHIER_SET_TRAY, tray);
  },
  [VuexTypes.CASHIER_UPDATE_TRAY]: ({ state, commit }, tray) => {
    commit(VuexTypes.CASHIER_SET_TRAY, tray);
  },
  [VuexTypes.CASHIER_TRAYS_ACTION_LOAD_IMAGE]: ({ state, commit }, tray) => {
    const storedTray = state.byOrder[tray.order_id].find(item => item.id === tray.id);

    if (!storedTray || storedTray.loadedImage) {
      return;
    }

    traysService.getResultImage(tray.id).then((res) => {
      const base64 = btoa(
        new Uint8Array(res.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          '',
        ),
      );

      commit(VuexTypes.CASHIER_TAYS_MUTATION_SET_IMAGE, { tray, imageData: `data:;base64,${base64}` });
    });
  },
  /* Initial tray load on page load */
  [VuexTypes.CASHIER_TRAYS_ACTION_FETCH_TRAYS]: ({ commit }) => {
    commit(VuexTypes.CASHIER_TRAYS_MUTATION_SET_LOADING, true);
    traysService.getForCashier()
      .then(resp => {
        resp.data.forEach(tray => {
          commit(VuexTypes.CASHIER_SET_TRAY, tray);
        });
        commit(VuexTypes.CASHIER_TRAYS_MUTATION_SET_LOADING, false);
      });
  },
  [VuexTypes.CASHIER_TRAYS_ACTION_SET_TRAY_PRODUCT]: ({ commit }, { tray, trayProduct }) => {
    commit(VuexTypes.CASHIER_TRAYS_MUTATION_SET_TRAY_PRODUCT, { tray, trayProduct });
  },
  [VuexTypes.CASHIER_TRAYS_ACTION_DELETE_TRAY]: ({ commit }, trayId) => {
    commit(VuexTypes.CASHIER_TRAYS_MUTATION_DELETE_TRAY, trayId);
  },
  [VuexTypes.CASHIER_TRAYS_ACTION_SET_TRAY_LOADING]: ({ commit, getters }, { trayId, loading }) => {
    const trayIndex = getters.getTrayIndex(trayId);

    if (trayIndex !== -1) {
      commit(VuexTypes.CASHIER_TRAYS_MUTATION_SET_TRAY_LOADING, { trayId, trayIndex, loading });
    } else {
      console.err(VuexTypes.CASHIER_TRAYS_MUTATION_SET_TRAY_LOADING + ' no index (tray not found)');
    }
  },
};


export default {
  state,
  mutations,
  actions,
  getters,
};
