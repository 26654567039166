<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col
        :sm="showForm ? '6' : '12'"
        :md="showForm ? '8' : '12'"
        :lg="showForm ? '9' : '12'"
      >
        <div class="card">
          <div class="card-header">
            <i class="fa fa-align-justify" /> {{ $t('combos.header') }}
            <b-btn
              class="pull-right ml-2 mt-1"
              size="sm"
              variant="success"
              @click.prevent="openAddForm()"
            >
              <i class="fas fa-plus" /> {{ $t('global.add_new') }}
            </b-btn>
            <!-- filters -->
            <b-btn
              v-for="language in activeLanguages"
              :key="language.key"
              class="pull-right mt-1"
              size="sm"
              :variant="filterPresent('untranslated_name', language.key) ? 'primary' : ''"
              @click="toggleFilterElements('untranslated_name', language.key)"
            >
              {{ $t('global.untranslated_name') }}: {{ language.name }}
            </b-btn>
            <b-btn
              class="pull-right mt-1"
              size="sm"
              :variant="filtersInactive ? 'primary' : ''"
              @click="clearFilters()"
            >
              {{ $t('global.show_all') }}
            </b-btn>
            <!-- end filters -->
            <b-input-group
              class="pull-right mr-2"
              style="width: 35%;"
            >
              <template #prepend>
                <b-input-group-text><i class="fas fa-search" /></b-input-group-text>
              </template>
              <b-form-input v-model.trim="productName" />
            </b-input-group>
          </div>
          <div class="card-block">
            <div class="table-responsive">
              <table class="table table-striped table-condensed">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{{ $t('global.name') }}</th>
                    <th v-if="activeLanguages.length > 0">
                      {{ $t('global.name_translations') }}
                    </th>
                    <th>{{ $t('global.actions') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in combos.list.data"
                    :key="item.id"
                  >
                    <td>{{ item.id }}</td>
                    <td>{{ item.name }}</td>
                    <td v-if="activeLanguages.length > 0">
                      <b-badge
                        v-for="translation, key in extractNameTranslations(item)"
                        :key="key"
                      >
                        {{ key }}: {{ translation }}
                      </b-badge>
                    </td>
                    <td>
                      <button
                        v-if="can('super-admin', 'cvision-admin')"
                        type="button"
                        class="btn btn-success btn-sm table-button-size"
                        @click="editItem(item)"
                      >
                        <i class="fa icon-pencil" /> {{ $t('global.edit') }}
                      </button>
                      <button
                        v-if="can('super-admin', 'cvision-admin')"
                        type="button"
                        class="btn btn-warning btn-sm table-button-size"
                        @click="deleteItem(item)"
                      >
                        <i class="fa fa-trash" /> {{ $t('global.delete') }}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-pagination
              v-model="currentPage"
              :total-rows="combos.list.total"
              :per-page="combos.list.per_page"
              class="mt-4"
            />
          </div>
        </div>
      </b-col>
      <b-col
        v-if="showForm"
        sm="6"
        md="4"
        lg="3"
      >
        <combo-form
          v-model="addItem"
          :show-cancel="true"
          @saved="formSaved"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { VuexTypes } from '@/store/types';
import ComboForm from './ComboForm.vue';
import ComboService from '@/services/combo-service';
import { getCategoryName } from '@/helpers/tray';

export default {
  name      : 'Combo',
  components: { ComboForm },
  data () {
    return {
      productName: '',
      filters    : this.makeDefaultFilters(),
      currentPage: 1,
      addItem    : {},
      searchTimeout: null,
      showAddForm  : false,
      getCategoryName,
    };
  },
  computed: {
    ...mapState(['combos']),
    ...mapGetters([
      'activeLanguages',
      'can',
    ]),
    showForm () {
      return this.addItem.id || this.showAddForm;
    },
    filtersActive() {
      for (const value of Object.values(this.filters)) {
        if (value.length > 0) { return true; }
      }
      return false;
    },
    filtersInactive() {
      return !this.filtersActive;
    },
  },
  watch: {
    roles      : 'onRolesLoaded',
    currentPage: function () {
      this.listAndFilter();
    },
    productName: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.listAndFilter();
      }, 250);
    },
    filters: {
      handler: function () {
        this.listAndFilter();
      },
      deep: true,
    },
  },
  mounted () {
    this.loadActiveLanguages();
    this.loadHumanProducts();
    this.listAndFilter();
  },
  methods: {
    ...mapActions({
      list  : VuexTypes.COMBOS_LIST,
      delete: VuexTypes.COMBOS_DELETE,

      loadActiveLanguages: VuexTypes.LANGUAGES_CLASSIFICATOR,
      loadHumanProducts: VuexTypes.HUMAN_PRODUCTS_LIST_ALL,
    }),
    editItem (item) {
      this.showAddForm = false;
      this.setAddItem(Object.assign({}, item));
    },
    deleteItem (item) {
      if (confirm(this.$t('global.delete_confirm_text'))) {
        this.delete(item.id);
        this.setAddItem({});
      }
    },
    formSaved (id) {
      if (!id) {
        this.showAddForm = false;
        this.setAddItem({});
        return; 
      }
      this.showAddForm = false;
      ComboService.get(id).then((item) => {
        if (item.data) {
          this.setAddItem(Object.assign({}, item.data));
        } else {
          this.setAddItem({});
        }
      }).catch(() => {
        this.setAddItem({});
      });
      this.listAndFilter();
    },
    openAddForm () {
      this.showAddForm = true;
      this.setAddItem({});
    },
    listAndFilter() {
      this.list({
        currentPage: this.currentPage,
        productName: this.productName,
        filters: this.filters,
      });
    },
    extractNameTranslations(item) {
      const nameTranslations = {};
      if (item?.translations?.name) {
        this.activeLanguages.forEach(language => {
          const translation = item.translations.name[language.key];
          if (translation) { nameTranslations[language.key] = translation; }
        });
      }
      return nameTranslations;
    },
    setAddItem(addItem) {
      if (!addItem?.translations?.name || addItem?.translations?.name.length === 0) {
        addItem.translations = { name: {} };
      }
      this.addItem = addItem;
    },
    toggleFilter(key, value) {
      const index = this.filters[key].indexOf(value);
      if (index < 0) {
        // Set
        this.filters[key] = [value];
      } else {
        // Clear
        this.filters[key] = [];
      }
    },
    toggleFilterElements(key, value) {
      const index = this.filters[key].indexOf(value);
      if (index < 0) {
        // Add
        this.filters[key].push(value);
      } else {
        // Remove
        this.filters[key].splice(index, 1);
      }
    },
    filterPresent(key, value) {
      return this.filters[key].indexOf(value) > -1;
    },
    clearFilters() {
      this.filters = this.makeDefaultFilters();
    },
    makeDefaultFilters() {
      return {
        untranslated_name: [],
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.action {
  margin-left: 5px;

  &:hover {
    color: #e04;
    cursor: pointer;
  }
}

.table-button-size {
  font-size: 11px;
  min-width: 50px;
}

.card-header .card-actions button.btn-action-green {
  background: solid;
  background-color: #cde4d1;
  border-color: #82a088;
  padding-left: 1em;
  padding-right: 1em;
  width: auto;
}

.badge {
  margin-right: 2px;
}
</style>
