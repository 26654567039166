<template>
  <b-card
    :class="{ 'bg-light-yellow': isEditing, 'bg-light-green': !isEditing }"
    header-tag="header"
  >
    <div slot="header">
      <span v-if="isEditing"><i class="fa icon-pencil" /> {{ $t('global.edit') }} </span>
      <span v-else><i class="fa icon-plus" /> {{ $t('global.add_new') }} </span>
      <div class="card-actions">
        <a
          href="#"
          class="btn btn-setting"
          @click="closeClick"
        >
          <i class="fa icon-close" />
        </a>
      </div>
    </div>
    <form @submit.prevent="save">
      <b-form-group
        :label="$t('cash_desk.inventory_number')"
        :state="!errors.inventory_number"
        :invalid-feedback="errors.inventory_number"
      >
        <b-form-input
          v-model="cash_desk.inventory_number"
          type="text"
        />
      </b-form-group>
      <b-form-group
        :label="$t('global.description')"
        :state="!errors.description"
        :invalid-feedback="errors.description"
      >
        <b-form-input
          v-model="cash_desk.description"
          type="text"
        />
      </b-form-group>
      <b-form-group
        :label="$t('global.type')"
        :state="!errors.type"
        :invalid-feedback="errors.type"
      >
        <b-form-select
          v-model="cash_desk.type"
          :options="types"
        />
      </b-form-group>
      <b-form-group
        :label="$t('cash_desk.host')"
        :state="!errors.host"
        :invalid-feedback="errors.host"
      >
        <b-form-input
          v-model="cash_desk.host"
          type="text"
        />
      </b-form-group>
      <b-form-group
        :label="$t('cash_desk.port')"
        :state="!errors.port"
        :invalid-feedback="errors.port"
      >
        <b-form-input
          v-model="cash_desk.port"
          type="text"
        />
      </b-form-group>
      <b-form-group
        :label="$t('cash_desk.station_id')"
        :state="!errors.station_id"
        :invalid-feedback="errors.station_id"
      >
        <b-form-input
          v-model="cash_desk.station_id"
          type="text"
        />
      </b-form-group>
      <b-form-group
        :label="$t('cash_desk.cashier_id')"
        :state="!errors.cashier_id"
        :invalid-feedback="errors.cashier_id"
      >
        <b-form-input
          v-model="cash_desk.cashier_id"
          type="text"
        />
      </b-form-group>
      <b-form-group
        :label="$t('cash_desk.payment_id')"
        :state="!errors.payment_id"
        :invalid-feedback="errors.payment_id"
      >
        <b-form-input
          v-model="cash_desk.payment_id"
          type="text"
        />
      </b-form-group>
      <b-form-group
        :label="$t('cash_desk.username')"
        :state="!errors.username"
        :invalid-feedback="errors.username"
      >
        <b-form-input
          v-model="cash_desk.username"
          type="text"
        />
      </b-form-group>
      <b-form-group
        :label="$t('cash_desk.password')"
        :description="isEditing ? $dt('cash_desk.existing_password_hidden', 'Existing password is hidden for security reasons.') : ''"
        :state="!errors.password"
        :invalid-feedback="errors.password"
      >
        <b-form-input
          v-model="cash_desk.password"
          :placeholder="isEditing ? $dt('cash_desk.new_password_placeholder', 'New password') : ''"
          type="text"
        />
      </b-form-group>
      <b-form-group
        :label="$t('cash_desk.table_code')"
        :state="!errors.table_code"
        :invalid-feedback="errors.table_code"
      >
        <b-form-input
          v-model="cash_desk.table_code"
          type="text"
        />
      </b-form-group>
      <div>
        <b-btn
          v-if="showCancel || isEditing"
          variant="outline-danger"
          @click="closeClick"
        >
          {{ $t('global.cancel') }}
        </b-btn>
        <b-btn
          v-if="isEditing"
          class="pull-right"
          type="submit"
          variant="success"
        >
          {{ $t('global.save') }}
        </b-btn>
        <b-btn
          v-else
          class="pull-right"
          type="submit"
          variant="success"
        >
          {{ $t('global.save') }}
        </b-btn>
      </div>
    </form>
  </b-card>
</template>
<script>

import { VuexTypes } from '@/store/types';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'CashDeskForm',

  props: {
    value: {
      type    : Object,
      required: true,
    },
    showCancel: {
      type    : Boolean,
      required: false,
      default () {
        return false;
      },
    },
  },
  emits: ['input'],
  data () {
    return {
      cash_desk: _.cloneDeep(this.value),
      errors: {},
      types : [
        { value: 'rkeeper', text: 'rkeeper' },
        { value: 'cc5', text: 'CC5'},
        { value: 'test', text: 'TEST'},
      ],
    };
  },
  computed: {
    ...mapState(['cashDesk']),
    isEditing () {
      return typeof this.cash_desk.id !== 'undefined' && this.cash_desk.id > 0;
    },
  },
  watch: {
    value: function (newVal) {
      this.cash_desk = newVal;
    },
  },
  mounted () {
  },
  methods: {
    ...mapActions({
      add   : VuexTypes.CASH_DESK_ADD,
      update: VuexTypes.CASH_DESK_UPDATE,
    }),
    closeClick () {
      this.$emit('saved', false);
    },
    save () {
      const action = this.isEditing ? this.update(this.cash_desk) : this.add(this.cash_desk);

      action
        .then(() => {
          this.errors = {};
          this.$emit('saved', true);
        }).catch((error) => {
          this.errors = {};
          if (typeof error.data !== 'undefined') {
            this.errors = error.data;
          }
          for (const item in error.data) {
            this.errors[item] = this.errors[item].join(' ');
          }
        });
    },
  },
};
</script>
<style>
</style>
